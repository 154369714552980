<template>
  <div class="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
  data() {
    return {
      server_address: process.env.VUE_APP_SERVER_ADDRESS,
      socket: null,
      connected: false,
    };
  },
  methods: {
    setupSocket() {
      this.gallerySocket = new WebSocket(
        `${process.env.VUE_APP_SOCKET_ADDRESS}/ws/sharing/`,
      );

      this.gallerySocket.onopen = (e) => {
        console.log(e);

        this.connected = true;
      };

      this.gallerySocket.onclose = (e) => {
        console.log(e);

        this.connected = false;

        console.log('Waiting 5s to reconnect');

        setTimeout(() => {
          this.setupSocket();
        }, 5000);
      };

      this.gallerySocket.onmessage = (e) => {
        const data = JSON.parse(e.data);

        if (data.type === 'send_initial_sessions') {
          this.$store.commit('setInitialBatches', data.sessions);
        }

        if (data.type === 'send_session') {
          const session = {
            id: data.id,
            input_file: data.input_file,
            output_file: data.output_file,
          };

          const currentId = (
            this.$store.state.currentBatch ? this.$store.state.currentBatch.id : null
          );

          if (!this.$store.state.batchQueue.some(({ id }) => id) && currentId !== session.id) {
            this.$store.commit('addBatch', session);
          }
        }
      };
    },
  },
  mounted() {
    this.setupSocket();
  },
};
</script>

<style>
@font-face {
  font-family: "Google Sans";
  src:  url("~@/assets/fonts/GoogleSans-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "Google Sans";
  src:  url("~@/assets/fonts/GoogleSans-Italic.ttf");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Google Sans";
  src:  url("~@/assets/fonts/GoogleSans-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Google Sans";
  src:  url("~@/assets/fonts/GoogleSans-MediumItalic.ttf");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Google Sans";
  src:  url("~@/assets/fonts/GoogleSans-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Google Sans";
  src:  url("~@/assets/fonts/GoogleSans-BoldItalic.ttf");
  font-weight: 700;
  font-style: italic;
}

body {
  background: #000;
  margin: 0;
  overscroll-behavior-y: contain;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  align-items: center;
  display: flex;
  font-family: "Google Sans", Helvetica, Arial, sans-serif;
  height: 100vh;
  justify-content: center;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100vw;
  text-align: center;
}

.app {
  align-items: center;
  display: flex;
  height: 100%;
  max-height: calc(100vw * 1.6);
  max-width: calc(100vh * 0.625);
  position: relative;
  width: 100%;
}
</style>
