<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<!-- eslint-disable vuejs-accessibility/media-has-caption -->
<template>
  <div>
    <div class="media-container">
      <div class="media-flex-container">
        <video
          v-if="isVideo"
          :src=mediaUrl
          muted
          playsinline
          loop
          autoplay
        />
        <img
          v-else
          :src=mediaUrl
          alt="Photo"
        />
      </div>
    </div>
    <div
      class="session-preview"
    >
      <div v-if="!showCancel && !showShare" class="default">
        <div class="buttons">
          <div class="button-container button-container-left">
            <img
              @click="toggleShare"
              class="button"
              :src="buttonImgs.share"
              alt="Share"
              oncontextmenu="return false;"
              @mousedown="buttonImgs.share = '/img/buttons/share_button_pressed.png'"
              @mouseup="buttonImgs.share = '/img/buttons/share_button.png'"
            />
          </div>
          <div class="button-container button-container-right">
            <img
              @click="toggleCancel"
              class="button"
              :src="buttonImgs.close"
              alt="Close"
              oncontextmenu="return false;"
              @mousedown="buttonImgs.close = '/img/buttons/close_button_pressed.png'"
              @mouseup="buttonImgs.close = '/img/buttons/close_button.png'"
            />
          </div>
        </div>
      </div>
      <div v-if="showCancel" class="cancel">
        <!-- <div class="text" v-html="closeText"/> -->
        <div class="buttons">
          <div class="button-container button-container-left">
            <img
              @click="finishSession"
              class="button"
              :src="buttonImgs.yes"
              alt="Yes"
              oncontextmenu="return false;"
              @mousedown="buttonImgs.yes = '/img/buttons/yes_button_pressed.png'"
              @mouseup="buttonImgs.yes = '/img/buttons/yes_button.png'"
            />
          </div>
          <div class="button-container button-container-right">
            <img
              @click="toggleCancel"
              class="button"
              :src="buttonImgs.no"
              alt="No"
              oncontextmenu="return false;"
              @mousedown="buttonImgs.no = '/img/buttons/no_button_pressed.png'"
              @mouseup="buttonImgs.no = '/img/buttons/no_button.png'"
            />
          </div>
        </div>
      </div>
      <div v-if="showShare" class="share">
        <!-- <div class="text" v-html="shareText"/> -->
        <div class="qr-container">
          <div class="qr-img-container">
            <img class="qr-code" alt="QR Code" :src=qrData />
          </div>
        </div>
        <div class="buttons">
          <div class="button-container">
            <img
              @click="toggleShare"
              class="button"
              :src="buttonImgs.close"
              alt="Close"
              oncontextmenu="return false;"
              @mousedown="buttonImgs.close = '/img/buttons/close_button_pressed.png'"
              @mouseup="buttonImgs.close = '/img/buttons/close_button.png'"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import QRCode from 'qrcode';

export default {
  name: 'SessionPreview',
  computed: {
    backgroundStyle() {
      let backgroundUrl = '';

      if (this.$store.state.currentBatch) {
        if (String(this.$store.state.currentBatch.input_file).startsWith('http')) {
          backgroundUrl = this.$store.state.currentBatch.input_file;
        } else {
          backgroundUrl = `${process.env.VUE_APP_SERVER_ADDRESS}${this.$store.state.currentBatch.input_file}`;
        }
      }

      return `background-image: url("${backgroundUrl}")`;
    },
    mediaUrl() {
      if (!this.$store.state.currentBatch) {
        return '';
      }

      if (String(this.$store.state.currentBatch.input_file).startsWith('http')) {
        return this.$store.state.currentBatch.input_file;
      }

      return `${process.env.VUE_APP_SERVER_ADDRESS}${this.$store.state.currentBatch.input_file}`;
    },
    isVideo() {
      return this.$store.state.currentBatch.input_file.endsWith('mp4');
    },
    closeText() {
      const mediaType = this.isVideo ? 'video' : 'photo';

      return `Are you sure you want to close this page?  Your ${mediaType} will be deleted`;
    },
    shareText() {
      const mediaType = this.isVideo ? 'video' : 'photo';

      return `Scan this QR code to download your ${mediaType}`;
    },
  },
  data() {
    return {
      showCancel: false,
      showShare: false,
      qrData: null,
      timeout: null,
      timeoutTime: process.env.VUE_APP_TIMEOUT ? Number(process.env.VUE_APP_TIMEOUT) : null,
      buttonImgs: {
        close: '/img/buttons/close_button.png',
        no: '/img/buttons/no_button.png',
        share: '/img/buttons/share_button.png',
        yes: '/img/buttons/yes_button.png',
      },
    };
  },
  methods: {
    toggleCancel() {
      this.showCancel = !this.showCancel;
    },
    toggleShare() {
      QRCode.toDataURL(
        `${process.env.VUE_APP_SERVER_ADDRESS}?id=${this.$store.state.currentBatch.id}`,
        {
          scale: 8,
        },
      ).then((i) => {
        this.qrData = i;
        this.showShare = !this.showShare;
      });
    },
    finishSession() {
      this.qrData = null;

      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      const url = `
        ${process.env.VUE_APP_SERVER_ADDRESS}/api/sessions/event-sessions/${this.$store.state.currentBatch.id}/
      `;

      axios.patch(
        url,
        {
          complete: true,
        },
        {
          headers: {
            Authorization: `Token ${this.$store.state.serverToken}`,
          },
        },
      )
        .then(() => {
          this.removeListeners();
          this.$store.commit('clearCurrentBatch');
          this.$router.push('/');
        });
    },
    setupTimeout() {
      if (!this.timeoutTime) {
        return;
      }

      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(this.finishSession, this.timeoutTime);
    },
    setupListeners() {
      // eslint-disable-next-line no-restricted-globals
      addEventListener('mousemove', this.setupTimeout);
    },
    removeListeners() {
      // eslint-disable-next-line no-restricted-globals
      removeEventListener('mousemove', this.setupTimeout);
    },
  },
  mounted() {
    this.setupListeners();
    this.setupTimeout();
  },
};
</script>

<style scoped>
.default {
  background-image: url('@/assets/preview_frame.png');
}

.default, .cancel, .share {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
}

.cancel, .share {
  background-color: #000000b3;
}

.cancel {
  background-image: url('@/assets/close_frame.png');
}

.share {
  background-image: url('@/assets/share_frame.png');
}

.cancel {
  align-items: center;
  display: flex;
  justify-content: center;
}

.session-preview {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}

.buttons {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 78%;
  width: 100%;
}

.button-container {
  display: flex;
  justify-content: center;
  width: 50%;
}

.button-container-left {
  justify-content: right;
}

.button-container-left .button {
  margin-right: 12%;
}

.button-container-right {
  justify-content: left;
}

.button-container-right .button {
  margin-left: 12%;
}

.button {
  width: 53%;
}

.button:hover {
  filter: grayscale(100);
}

.text {
  color: #000;
  font-size: 4.9vh;
  font-weight: bold;
  margin: auto;
  padding-top: 2vh;
  width: 90%;
}

.qr-container {
  align-items: center;
  display: flex;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}

.qr-img-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.qr-code {
  height: auto;
  width: 40%;
}

.share .text {
  font-size: 5vh;
  padding-top: 17.5vh;
  width: 90%;
}

.video-container {
  height: 100%;
  overflow: hidden;
  /* position: absolute; */
  top: 0;
  width: 100%;
}

.media-container video, img {
  width: 100%;
}

.media-flex-container {
  display: flex;
}
</style>
